// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('sans serif'), local('sans-serif'), url(../assets/fonts/Roboto/Roboto-Regular.ttf) format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: auto;
    font-weight: 300;
    src: local('sans serif'), local('sans-serif'), url(../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('opentype');
    font-display: swap;
}


:root {
    --blue: #2d3cff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #ff00d2;
    --red: #ff3000;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #868e96;
    --gray-dark: #343a40;
    --primary: #2d3cff;
    --secondary: #ff00d2;
    --success: #00713d;
    --info: #17a2b8;
    --warning: #fff1de;
    --danger: #ff3000;
    --light: #f8f9fa;
    --dark: #343a40;
    --navy: #19194b;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Roboto", sans-serif;
    --font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    text-align: left;
    background-color: #ffffff;
    letter-spacing: 0.063rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #00713d;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    text-align: justify;
    margin-top: 0;
    margin-bottom: 1rem;
}

.font-small {
    font-size: small;

    &.zoomed-on-md {
        @media (max-width: 991px) {
            font-size: larger;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    line-height: 1.3;
}

h1,
.h1 {
    font-size: 2.5rem !important;
}

h2,
.h2 {
    font-size: 2rem !important;
}

h3,
.h3 {
    font-size: 1.75rem !important;
}

h4,
.h4 {
    font-size: 1.5rem !important;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #ffffff;
    border: 0.0625rem solid #dee2e6;
    border-radius: 0.4rem;
    max-width: 100%;
    height: auto;
}

.invalid-feedback {
    display: none;
    width: 100%;
    padding-top: 0;
    font-size: 80%;
    color: #ff3000;
    max-height: 0;
}

.nav-link {
    color: #0e3b18;
    display: block;
    padding: 0.5rem 1rem 0.5rem 0;
}

.shadow {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

input.ng-invalid {
    &.invalid {
        border: 0.0625rem solid red;
    }
}

input.ng-valid {
    &.valid {
        border: 0.0625rem solid gray;
    }
}

form {
    span.text-danger {
        display: none !important;
    }

    &.error {
        span.text-danger {
            display: block !important;
        }

        input.ng-invalid {
            border: 1px solid red;
        }
    }
}

/* General layout overwrite */
a.btn {
    display: inline-block;
    box-shadow: 4px 3px 5px #ddd;
    background: #00713d;
    color: white;
    font-family: 'Museo Sans 900', serif;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #00713d;
    padding: 6px 20px;
    border-radius: 7px;
}

input.btn {
    display: inline-block;
    background: #00713d;
    color: white;
    font-weight: bold;
    font-family: 'Museo Sans 900', sans-serif;
    text-transform: uppercase;
    border: 1px solid #00713d;
    padding: 6px 20px;
    border-radius: 7px;
}

.anchor {
    display: block;
    position: relative;
    visibility: hidden;
    top: -100px;
}

.text-success {
    color: #00713d !important;
}

.bg-success {
    background: #00713d !important;
}

.btn-success {
    background-color: #00713d !important; /* Set a background color */
}

.bg-light {
    background-color: #e7e7e8 !important
}

.text-underline {
    text-decoration: underline;
}

.fixed-top-lg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    @media (max-width: 991px) {
        position: relative;
    }
}

#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
}

.fa-bars:before {
    color: #0e3b18;
}